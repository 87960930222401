<template>
    <section class="vh-100" style="background: #F5F9FF;">
    <b-img class="position-absolute m-2" src="@/assets/images/logo-horizontal.svg" alt="logo"/>
        <div class="container w-md-50 h-100">
            
            

            <div class="h-100 d-flex flex-column align-items-center justify-content-center">

                <div class="w-100 bg-white border border-2 border-primary mb-4 rounded-sm">
                    <div class="bg-secondary-light py-1 px-2 rounded-top">
                        <span class="text-primary font-weight-bold">Crea una nuova area di lavoro - step {{step}}</span>
                    </div>

                    <div class="bg-white px-2 py-1">

                        <!-- Primo Step -->

                        <div v-if="step == 1">
                            <h2 class="text-primary-light mb-2">Crea una nuova area di lavoro di AutoinCloud!</h2>
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <p>
                                        AutoinCloud offre al team una casa, un luogo in cui comunicare e collaborare.
                                        <br>
                                        Per creare una nuova area di lavoro clicca sul pulsante qui sotto.
                                    </p>

                                    <b-button class="my-1" variant="primary" @click="nextStep">Crea una nuova area di lavoro</b-button>

                                    <p class="mt-1">
                                        Continuando, accetti le nostre Condizioni d'uso per il 
                                        <br>
                                        cliente, Condizioni d'uso per l'utente, Informativa sulla
                                        <br>
                                        privacy e informativa sui cookie.
                                    </p>
                                </div>

                                <div class="flex-grow-1 text-center">
                                    <b-img height="120" src="@/assets/images/logo.png" />
                                </div>
                            </div>
                        </div>

                        <!-- Secondo Step -->

                        <div v-if="step == 2">
                            <h2 class="text-primary-light mb-2">Qual è il nome della tua azienda o team?</h2>
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <p>
                                        Questo sarà il nome della tua area di lavoro in AutoinCloud:
                                        <br>
                                        Scegli qualcosa che il team sarà in grado di riconoscere.
                                    </p>

                                    <b-form-group>
                                        <b-form-input placeholder="Nome azienda srl"></b-form-input>
                                    </b-form-group>

                                    <b-button class="my-1" variant="primary" @click="nextStep">Avanti</b-button>

                                </div>

                                <div class="flex-grow-1 text-center">
                                    <b-img height="120" src="@/assets/images/logo.png" />
                                </div>
                            </div>
                        </div>

                        <!-- Terzo Step-->

                        <div v-if="step == 3">
                            <h2 class="text-primary-light mb-2">Inserisci le email del tuo staff</h2>
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <p>
                                        Per usare AutoinCloud, aggiungi i tuoi dipendenti con i rispettivi ruoli
                                    </p>

                                    <b-row v-for="user in users" :key="user">


                                    <b-col md="6">
                                        <b-form-group >
                                            <b-form-input  placeholder="Nome azienda srl"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    
                                    <b-col md="6">
                                        <b-form-group>
                                            <b-form-input placeholder="Ruolo"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                   
                                    </b-row>

                                    <b-row>
                                         <b-col md="6">
                                        <span class="cursor-pointer d-flex align-items-center text-primary-light font-weight-bolder"
                                              @click="users++"
                                        >
                                            <feather-icon class="mr-1" size="20" icon="PlusCircleIcon" />    
                                            Aggiungi un altro
                                        </span>
                                    </b-col>
                                            
                                    <b-col md="6">
                                        <span class="cursor-pointer d-flex align-items-center text-primary-light font-weight-bolder">
                                            <feather-icon class="mr-1" size="20" icon="PaperclipIcon" />    
                                             Ottieni un link di invito condivisibile
                                        </span>
                                    </b-col>
                                    </b-row>

                                    

                                    <div class="mt-2">
                                        <b-button class="my-1 mr-2" variant="primary">Aggiungi membri del team</b-button>
                                        <b-button class="font-weight-bolder" variant="link" @click="$router.push('/search_car')">Salta questo passaggio</b-button>
                                    </div>

                                </div>

                                <div class="flex-grow-1 text-center">
                                    <b-img height="120" src="@/assets/images/logo.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

           
        </div>

        
    </section>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BButton, BImg } from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BButton,
        BImg,
    },

    data: () => ({
        step: 1,
        maxStep: 3,
        users: 2,
    }),

    methods: ({
        nextStep() {
            if(this.step < this.maxStep) {
                this.step++;
                return this.step;
            }
        }
    })
}

</script>
